import axios from "axios";
import Cookies from 'js-cookie'

axios.defaults.baseURL = "https://adminapi.queqiaoxiangqin.com/"
// axios.defaults.baseURL = "http://fbadmin.laobitang.com/"

// 添加响应拦截器1
axios.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        const {code, msg} = response.data;
        if (code === 200 || code === 500) {
            return response;
        }
        return Promise.reject(msg);
    },
    function () {
        // 对响应错误做点什么
        return Promise.reject("服务器错误");
    }
);

export const TOKEN_KEY = 'token'
export const USER_ID = 'userId'
export const USER_NAME = 'userName'

export const setToken = (token) => {
    let millisecond = new Date().getTime()
    let expiresTime = new Date(millisecond + 60 * 1000 * 240)
    Cookies.set(TOKEN_KEY, token, {expires: expiresTime})
}

export const getToken = () => {
    const token = Cookies.get(TOKEN_KEY)
    if (token) return token
    else return false
}

export const setUserId = (userId) => {
    Cookies.set(USER_ID, userId)
}

export const setUserName = (userName) => {
    Cookies.set(USER_NAME, userName)
}

export const login = ({n, p}) => {
    const data = {
        n,
        p
    }
    return axios.request({
        url: '/v1/userAdmin/login',
        data: {
            name: data.n,
            password: data.p
        },
        method: 'post'
    })
}

export const showRegister = (params) => {
    return axios.request({
        url: '/v1/user/showRegister',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showUser = (params) => {
    return axios.request({
        url: '/v1/user/showUser',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeEnable = (data) => {
    return axios.request({
        url: '/v1/user/changeEnable',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showUserInfo = (params) => {
    return axios.request({
        url: '/v1/userInfo/showUserInfo',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeUserInfo = (data) => {
    return axios.request({
        url: '/v1/userInfo/changeUserInfo',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const changePerfect = (data) => {
    return axios.request({
        url: '/v1/userInfo/changePerfect',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const getGender = (params) => {
    return axios.request({
        url: '/v1/userInfo/getGender',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getDistrict = (params) => {
    return axios.request({
        url: '/v1/district/getDistrict',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getZodiac = (params) => {
    return axios.request({
        url: '/v1/userInfo/getZodiac',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getEdu = (params) => {
    return axios.request({
        url: '/v1/userInfo/getEdu',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getIncome = (params) => {
    return axios.request({
        url: '/v1/userInfo/getIncome',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getCar = (params) => {
    return axios.request({
        url: '/v1/userInfo/getCar',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getHouse = (params) => {
    return axios.request({
        url: '/v1/userInfo/getHouse',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getMarital = (params) => {
    return axios.request({
        url: '/v1/userInfo/getMarital',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getPerfect = (params) => {
    return axios.request({
        url: '/v1/userInfo/getPerfect',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getIsReal = (params) => {
    return axios.request({
        url: '/v1/userInfo/getIsReal',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getAge = (params) => {
    return axios.request({
        url: '/v1/userInfo/getAge',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getHeight = (params) => {
    return axios.request({
        url: '/v1/userInfo/getHeight',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const getWeight = (params) => {
    return axios.request({
        url: '/v1/userInfo/getWeight',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showOrder = (params) => {
    return axios.request({
        url: '/v1/order/showOrder',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const changeOrder = (data) => {
    return axios.request({
        url: '/v1/order/changeOrder',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}

export const showIncome = (params) => {
    return axios.request({
        url: '/v1/order/showIncome',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showStar = (params) => {
    return axios.request({
        url: '/v1/star/showStar',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showPhone = (params) => {
    return axios.request({
        url: '/v1/phoneShow/showPhone',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showShare = (params) => {
    return axios.request({
        url: '/v1/shareNum/showShare',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showLogoff = (params) => {
    return axios.request({
        url: '/v1/logoff/showLogoff',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const showUserVip = (params) => {
    return axios.request({
        url: '/v1/userVip/showUserVip',
        headers: {'token': getToken()},
        params: params,
        method: 'get'
    })
}

export const addUserVipTime = (data) => {
    return axios.request({
        url: '/v1/userVip/addUserVipTime',
        headers: {'token': getToken()},
        data: data,
        method: 'post'
    })
}
