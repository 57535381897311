<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>时间范围：</div>
        </el-col>
        <el-col :span="7">
          <el-date-picker
              v-model="value1"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="showRegister()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-button type="primary" @click="refresh">刷新</el-button>
      <br><br>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="时间" width="100"/>
        <el-table-column prop="number1" label="注册" width="100"/>
        <el-table-column prop="number2" label="活跃" width="100"/>
        <el-table-column prop="number3" label="注销" width="100"/>
        <el-table-column prop="number4" label="提交资料" width="100"/>
        <el-table-column prop="number5" label="审核通过" width="100"/>
        <el-table-column prop="number6" label="订单数量" width="100"/>
        <el-table-column prop="number7" label="订单金额" width="100"/>
        <el-table-column prop="range" label="时间范围"/>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {
  formatDateTime
} from "@/libs/tools";
import {
  showRegister
} from "@/https";

export default {
  name: "UserRegister",
  data() {
    return {
      tableData: [],
      value1: ''
    }
  },
  methods: {
    refresh() {
      this.tableData = []
      this.showRegister()
    },
    showRegister() {
      this.tableData = []
      return new Promise(() => {
        showRegister({
          createStart: this.value1[0],
          createEnd: this.value1[1]
        }).then(res => {
          this.tableData.push({
            date: "今天",
            number1: res.data.data.userTodayCreate,
            number2: res.data.data.userTodayLogin,
            number3: res.data.data.userTodayLogoff,
            number4: res.data.data.userTodayVerify,
            number5: res.data.data.userTodayPerfect,
            number6: res.data.data.userTodayOrderNum,
            number7: res.data.data.userTodayOrderPay,
            range: formatDateTime(res.data.data.userTodayTime * 1000) + "----" + formatDateTime(Date.now())
          },{
            date: "七天内",
            number1: res.data.data.userWeekCreate,
            number2: res.data.data.userWeekLogin,
            number3: res.data.data.userWeekLogoff,
            number4: res.data.data.userWeekVerify,
            number5: res.data.data.userWeekPerfect,
            number6: res.data.data.userWeekOrderNum,
            number7: res.data.data.userWeekOrderPay,
            range: formatDateTime(res.data.data.userWeekTime * 1000) + "----" + formatDateTime(Date.now())
          }, {
            date: "本月",
            number1: res.data.data.userMonthCreate,
            number2: res.data.data.userMonthLogin,
            number3: res.data.data.userMonthLogoff,
            number4: res.data.data.userMonthVerify,
            number5: res.data.data.userMonthPerfect,
            number6: res.data.data.userMonthOrderNum,
            number7: res.data.data.userMonthOrderPay,
            range: formatDateTime(res.data.data.userMonthTime * 1000) + "----" + formatDateTime(Date.now())
          },{
            date: "总计",
            number1: res.data.data.userRegister,
            number3: res.data.data.userLogoff,
            number4: res.data.data.userVerify,
            number5: res.data.data.userPerfect,
            number6: res.data.data.userOrderNum,
            number7: res.data.data.userOrderPay,
            range: formatDateTime(1640966400000) + "----" + formatDateTime(Date.now())
          },{
            date: "时间段内",
            number1: res.data.data.userTimeCreate,
            number2: res.data.data.userTimeLogin,
            number3: res.data.data.userTimeLogoff,
            number4: res.data.data.userTimeVerify,
            number5: res.data.data.userTimePerfect,
            number6: res.data.data.userTimeOrderNum,
            number7: res.data.data.userTimeOrderPay,
            range: res.data.data.userTimeAT === null ? null : formatDateTime(res.data.data.userTimeAT * 1000) + "----" + formatDateTime(res.data.data.userTimeEnd * 1000)
          })
        })
      })
    },
    resetButtonValue() {
      this.value1 = ''
      this.showRegister()
    },
  },
  mounted: function () {
    this.refresh();
  }

}
</script>

<style scoped>

</style>