<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5" :offset="0.5">
          <div>性别：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.gender" clearable placeholder="请选择">
            <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>年龄：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.age" clearable placeholder="请选择">
            <el-option
                v-for="item in options12"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>身高：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.height" clearable placeholder="请选择">
            <el-option
                v-for="item in options13"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>体重：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.weight" clearable placeholder="请选择">
            <el-option
                v-for="item in options14"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <br><br><br>
        <el-col :span="1.5" :offset="0.5">
          <div>省份：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.province" clearable placeholder="请选择" @change="getCity1">
            <el-option
                v-for="item in options10"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>城市：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.city" clearable placeholder="请选择">
            <el-option
                v-for="item in options11"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>生肖：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.zodiac" clearable placeholder="请选择">
            <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>学历：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.edu" clearable placeholder="请选择">
            <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <br><br><br>
        <el-col :span="1.5" :offset="0.5">
          <div>收入：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.income" clearable placeholder="请选择">
            <el-option
                v-for="item in options4"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>车辆：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.car" clearable placeholder="请选择">
            <el-option
                v-for="item in options5"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>房子：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.house" clearable placeholder="请选择">
            <el-option
                v-for="item in options6"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>婚姻：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.marital" clearable placeholder="请选择">
            <el-option
                v-for="item in options7"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <br><br><br>
        <el-col :span="1.5" :offset="0.5">
          <div>状态：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.perfect" clearable placeholder="请选择">
            <el-option
                v-for="item in options8"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>实名：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="userInfo.isReal" clearable placeholder="请选择">
            <el-option
                v-for="item in options9"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5">
          <div>编号：</div>
        </el-col>
        <el-col :span="3">
          <el-input v-model="userInfo.userId"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>手机：</div>
        </el-col>
        <el-col :span="3">
          <el-input v-model="userInfo.parentPhone"></el-input>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="userInfo.pageNum=1,showUserInfo()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="编号" width="80"/>
        <el-table-column prop="parentPhone" label="手机号" width="120"/>
        <el-table-column prop="img" label="照片" width="105">
          <template slot-scope="scope">
            <el-popover placement="left" trigger="click" width="300">
              <img :src="scope.row.img" width="100%"/>
              <img
                  slot="reference"
                  :src="scope.row.img"
                  :alt="scope.row.img"
                  style="max-height: 70px;max-width: 70px; padding: 5px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="gender" label="性别" width="50"/>
        <el-table-column prop="province" label="省份" width="80"/>
        <el-table-column prop="city" label="城市" width="80"/>
        <el-table-column prop="age" label="年龄" width="60"/>
        <el-table-column prop="height" label="身高" width="50"/>
        <el-table-column prop="weight" label="体重" width="50"/>
        <el-table-column prop="zodiac" label="生肖" width="50"/>
        <el-table-column prop="edu" label="学历" width="60"/>
        <el-table-column prop="income" label="收入" width="110"/>
        <el-table-column prop="car" label="车辆" width="110"/>
        <el-table-column prop="house" label="房产" width="80"/>
        <el-table-column prop="marital" label="婚姻" width="80"/>
        <el-table-column prop="job" label="职业" width="100"/>
        <el-table-column prop="school" label="学校" width="100"/>
        <el-table-column prop="perfect" label="资料完善" width="100"/>
        <el-table-column prop="isReal" label="实名认证" width="100"/>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                type="primary">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="userInfo.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="userInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="修改用户信息" :visible.sync="dialogFormVisible" center width="1000px" :modal-append-to-body="false">
      <el-form :model="userInfoModel">
        <el-row>
          <el-col :span="5">
            <el-form-item label="用户Id" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.userId" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="称呼" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.parentName" style="width: 120px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="手机号" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.parentPhone" style="width: 120px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="家长头像" :label-width="formLabelWidth">
              <el-upload
                  class="avatar-uploader"
                  action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
                  name="file"
                  :show-file-list="false"
                  :on-success="handleParentSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="userInfoModel.parentImg" :src="userInfoModel.parentImg" class="avatar" alt="">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="孩子照片" :label-width="formLabelWidth">
              <el-upload
                  class="avatar-uploader"
                  action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
                  name="file"
                  :show-file-list="false"
                  :on-success="handleChildSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="userInfoModel.img" :src="userInfoModel.img" class="avatar" alt="">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="性别" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.gender" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="省份" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.province" placeholder="请选择" @change="getCity2" style="width: 120px;">
                <el-option
                    v-for="item in options10"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="城市" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.city" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options11"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="年龄" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.age" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options12"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="身高" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.height" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options13"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="体重" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.weight" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options14"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="学历" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.edu" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options3"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="收入" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.income" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options4"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="车辆" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.car" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options5"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="房子" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.house" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options6"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="婚姻" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.marital" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options7"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="职业" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.job" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="学校" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.school" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="说明" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.detailed"
                        style="width: 320px;"
                        type="textarea"
                        autosize></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="状态" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.perfect" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options8"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="实名" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.isReal" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options9"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeUserInfo(userInfoModel)">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {
  changeUserInfo,
  getAge,
  getCar, getDistrict,
  getEdu,
  getGender, getHeight,
  getHouse,
  getIncome,
  getIsReal,
  getMarital,
  getPerfect, getWeight,
  getZodiac,
  showUserInfo
} from "@/https";

export default {
  name: "UserInfo.vue",
  data() {
    return {
      userInfo: {
        userId: "",
        gender: "",
        zodiac: "",
        edu: "",
        income: "",
        car: "",
        house: "",
        marital: "",
        perfect: "",
        isReal: "",
        province: "",
        city: "",
        age: "",
        height: "",
        weight: "",
        parentPhone: "",
        pageNum: 1,
        limit: 10
      },
      userInfoModel: {
        userId: "",
        parentName: "",
        parentPhone: "",
        parentImg: "",
        gender: "",
        img: "",
        province: "",
        provinceShow: "",
        city: "",
        cityShow: "",
        age: "",
        height: "",
        weight: "",
        zodiac: "",
        edu: "",
        income: "",
        car: "",
        house: "",
        job: "",
        school: "",
        marital: "",
        detailed: "",
        perfect: "",
        isReal: "",
        reCityList: "",
        reProvinceList: "",
        reEdu: "",
        reHeightMin: "",
        reHeightMax: "",
        reAgeMin: "",
        reAgeMax: "",
        reCar: "",
        reHouse: "",
        reMarital: ""
      },
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
      options6: [],
      options7: [],
      options8: [],
      options9: [],
      options10: [],
      options11: [],
      options12: [],
      options13: [],
      options14: [],
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: 0,
      file: ''
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.userInfo.limit = val
      this.showUserInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.userInfo.pageNum = val
      this.showUserInfo()
    },
    handleParentSuccess(res) {
      this.userInfoModel.parentImg = res.key
    },
    handleChildSuccess(res) {
      this.userInfoModel.img = res.key
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    resetButtonValue() {
      this.userInfo.userId = ''
      this.userInfo.gender = ''
      this.userInfo.age = ''
      this.userInfo.height = ''
      this.userInfo.weight = ''
      this.userInfo.zodiac = ''
      this.userInfo.edu = ''
      this.userInfo.income = ''
      this.userInfo.car = ''
      this.userInfo.house = ''
      this.userInfo.marital = ''
      this.userInfo.perfect = ''
      this.userInfo.isReal = ''
      this.userInfo.province = ''
      this.userInfo.city = ''
      this.userInfo.parentPhone = ''
      this.userInfo.pageNum = 1
      this.userInfo.limit = 10
      this.showUserInfo()
    },
    showUserInfo() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showUserInfo({
          userId: this.userInfo.userId,
          gender: this.userInfo.gender,
          age: this.userInfo.age,
          height: this.userInfo.height,
          weight: this.userInfo.weight,
          province: this.userInfo.province,
          city: this.userInfo.city,
          zodiac: this.userInfo.zodiac,
          edu: this.userInfo.edu,
          income: this.userInfo.income,
          car: this.userInfo.car,
          house: this.userInfo.house,
          marital: this.userInfo.marital,
          perfect: this.userInfo.perfect,
          isReal: this.userInfo.isReal,
          parentPhone: this.userInfo.parentPhone,
          pageNum: this.userInfo.pageNum,
          limit: this.userInfo.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              userId: data.list[i].userId,
              parentPhone: data.list[i].parentPhone,
              img: data.list[i].img,
              gender: data.list[i].genderShow,
              province: data.list[i].provinceShow,
              city: data.list[i].cityShow,
              age: data.list[i].age,
              height: data.list[i].height,
              weight: data.list[i].weight,
              edu: data.list[i].eduShow,
              income: data.list[i].incomeShow,
              zodiac: data.list[i].zodiacShow,
              car: data.list[i].carShow,
              house: data.list[i].houseShow,
              marital: data.list[i].maritalShow,
              job: data.list[i].job,
              school: data.list[i].school,
              perfect: data.list[i].perfectShow,
              isReal: data.list[i].isRealShow
            })
          }
        })
      })
    },
    handleEdit(index, row) {
      return new Promise(() => {
        showUserInfo({
          userId: row.userId,
        }).then(res => {
          let data = res.data.data
          this.dialogFormVisible = true
          this.userInfoModel.userId = data.list[0].userId
          this.userInfoModel.parentName = data.list[0].parentName
          this.userInfoModel.parentPhone = data.list[0].parentPhone
          this.userInfoModel.parentImg = data.list[0].parentImg
          this.userInfoModel.img = data.list[0].img
          this.userInfoModel.gender = data.list[0].gender
          this.userInfoModel.province = data.list[0].province
          this.userInfoModel.provinceShow = data.list[0].provinceShow
          this.userInfoModel.city = data.list[0].city
          this.userInfoModel.cityShow = data.list[0].cityShow
          this.userInfoModel.age = data.list[0].age
          this.userInfoModel.height = data.list[0].height
          this.userInfoModel.weight = data.list[0].weight
          this.userInfoModel.edu = data.list[0].edu
          this.userInfoModel.income = data.list[0].income
          this.userInfoModel.zodiac = data.list[0].zodiac
          this.userInfoModel.car = data.list[0].car
          this.userInfoModel.house = data.list[0].house
          this.userInfoModel.marital = data.list[0].marital
          this.userInfoModel.job = data.list[0].job
          this.userInfoModel.school = data.list[0].school
          this.userInfoModel.detailed = data.list[0].detailed
          this.userInfoModel.perfect = data.list[0].perfect
          this.userInfoModel.isReal = data.list[0].isReal
          this.getCity2()
        })
      })
    },
    changeUserInfo() {
      return new Promise(() => {
        changeUserInfo({
          userId: this.userInfoModel.userId,
          parentName: this.userInfoModel.parentName,
          parentPhone: this.userInfoModel.parentPhone,
          parentImg: this.userInfoModel.parentImg,
          img: this.userInfoModel.img,
          gender: this.userInfoModel.gender,
          province: this.userInfoModel.province,
          city: this.userInfoModel.city,
          age: this.userInfoModel.age,
          height: this.userInfoModel.height,
          weight: this.userInfoModel.weight,
          edu: this.userInfoModel.edu,
          income: this.userInfoModel.income,
          zodiac: this.userInfoModel.zodiac,
          car: this.userInfoModel.car,
          house: this.userInfoModel.house,
          marital: this.userInfoModel.marital,
          job: this.userInfoModel.job,
          school: this.userInfoModel.school,
          detailed: this.userInfoModel.detailed,
          perfect: this.userInfoModel.perfect,
          isReal: this.userInfoModel.isReal
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showUserInfo()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    getGender() {
      return new Promise(() => {
        getGender({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options1.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getZodiac() {
      return new Promise(() => {
        getZodiac({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options2.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getEdu() {
      return new Promise(() => {
        getEdu({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options3.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getIncome() {
      return new Promise(() => {
        getIncome({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options4.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getCar() {
      return new Promise(() => {
        getCar({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options5.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getHouse() {
      return new Promise(() => {
        getHouse({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options6.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getMarital() {
      return new Promise(() => {
        getMarital({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options7.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getPerfect() {
      return new Promise(() => {
        getPerfect({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options8.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getIsReal() {
      return new Promise(() => {
        getIsReal({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options9.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getProvince() {
      return new Promise(() => {
        getDistrict({
          pid: 1
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options10.push({
              value: data[i].id,
              name: data[i].district,
            })
          }
        })
      })
    },
    getCity1() {
      this.options11 = []
      this.userInfo.city = ''
      return new Promise(() => {
        getDistrict({
          pid: this.userInfo.province
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options11.push({
              value: data[i].id,
              name: data[i].district,
            })
          }
        })
      })
    },
    getCity2() {
      this.options11 = []
      return new Promise(() => {
        getDistrict({
          pid: this.userInfoModel.province
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options11.push({
              value: data[i].id,
              name: data[i].district,
            })
          }
        })
      })
    },
    getAge() {
      return new Promise(() => {
        getAge({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options12.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getHeight() {
      return new Promise(() => {
        getHeight({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options13.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getWeight() {
      return new Promise(() => {
        getWeight({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options14.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showUserInfo();
    this.getGender();
    this.getZodiac();
    this.getEdu();
    this.getCar();
    this.getHouse();
    this.getIncome();
    this.getMarital();
    this.getPerfect();
    this.getIsReal();
    this.getProvince();
    this.getAge();
    this.getHeight();
    this.getWeight();
  }
}
</script>

<style scoped>
.el-upload--picture-card {
  width: 50px;
  height: 50px;
}

.el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.avatar {
  width: 70px;
  height: 70px;
}

.el-dialog {
  transform: translateY(-50%);
  margin-top: 0 !important;
  top: 50%;
}


</style>