<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="userInfo.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>审核状态：</div>
        </el-col>
        <el-col :span="4">
          <el-select v-model="userInfo.perfect" placeholder="请选择">
            <el-option
                v-for="item in options8"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="userInfo.pageNum=1,showVerify()">查询
        </el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
        <el-button style="margin-left: 20px" type="primary" @click="matchmaker_dialog = true">上传红娘微信</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="审核是否通过" width="160">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                @click="changeVerify(scope.$index, scope.row,1,reason)">通过
            </el-button>
            <el-popover
                placement="right"
                width="160"
                style="margin-left: 10px"
                trigger="hover"
                v-model="scope.row.visible">
              <p>请选择未通过原因</p>
              <div style="text-align: left; margin: 0">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox label="非本人照片"></el-checkbox>
                  <el-checkbox label="资料不真实"></el-checkbox>
                </el-checkbox-group>
                <br>
                <el-button type="primary"
                           size="mini"
                           @click="changeVerify(scope.$index, scope.row,3,checkList),scope.row.visible = false">
                  确定
                </el-button>
              </div>
              <el-button slot="reference" size="mini" type="danger">不通过</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="perfect" label="审核状态" width="100"/>
        <el-table-column prop="isReal" label="实名认证" width="100"/>
        <el-table-column prop="userId" label="编号" width="80"/>
        <el-table-column prop="parentName" label="家长称呼" width="100"/>
        <el-table-column prop="parentImg" label="家长头像" width="110">
          <template slot-scope="scope">
            <el-popover placement="left" trigger="click" width="300">
              <img :src="scope.row.parentImg" width="100%"/>
              <img
                  slot="reference"
                  :src="scope.row.parentImg"
                  :alt="scope.row.parentImg"
                  style="max-height: 70px;max-width: 70px; padding: 5px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="img" label="孩子照片" width="110">
          <template slot-scope="scope">
            <el-popover placement="left" trigger="click" width="300">
              <img :src="scope.row.img" width="100%"/>
              <img
                  slot="reference"
                  :src="scope.row.img"
                  :alt="scope.row.img"
                  style="max-height: 70px;max-width: 70px; padding: 5px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="job" label="职业" width="100"/>
        <el-table-column prop="school" label="学校" width="150"/>
        <el-table-column prop="detailed" label="相亲说明"/>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                type="primary">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="userInfo.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="userInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="修改用户信息" :visible.sync="dialogFormVisible" center width="1000px" :modal-append-to-body="false">
      <el-form :model="userInfoModel">
        <el-row>
          <el-col :span="5">
            <el-form-item label="用户Id" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.userId" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="称呼" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.parentName" style="width: 120px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="手机号" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.parentPhone" style="width: 120px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="家长头像" :label-width="formLabelWidth">
              <el-upload
                  class="avatar-uploader"
                  action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
                  name="file"
                  :show-file-list="false"
                  :on-success="handleParentSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="userInfoModel.parentImg" :src="userInfoModel.parentImg" class="avatar" alt="">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="孩子照片" :label-width="formLabelWidth">
              <el-upload
                  class="avatar-uploader"
                  action="http://fbadmin.laobitang.com/v2/userInfo/uploadPicture"
                  name="file"
                  :show-file-list="false"
                  :on-success="handleChildSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="userInfoModel.img" :src="userInfoModel.img" class="avatar" alt="">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="性别" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.gender" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="省份" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.province" placeholder="请选择" @change="getCity2" style="width: 120px;">
                <el-option
                    v-for="item in options10"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="城市" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.city" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options11"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="年龄" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.age" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options12"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="身高" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.height" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options13"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="体重" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.weight" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options14"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="学历" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.edu" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options3"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="收入" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.income" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options4"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="车辆" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.car" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options5"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="房子" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.house" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options6"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="婚姻" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.marital" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options7"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="职业" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.job" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="学校" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.school" style="width: 120px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="说明" :label-width="formLabelWidth">
              <el-input v-model="userInfoModel.detailed"
                        style="width: 320px;"
                        type="textarea"
                        autosize></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="状态" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.perfect" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options8"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="实名" :label-width="formLabelWidth">
              <el-select v-model="userInfoModel.isReal" placeholder="请选择" style="width: 120px;">
                <el-option
                    v-for="item in options9"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeUserInfo(userInfoModel)">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog
        title="上传红娘微信号"
        :visible.sync="matchmaker_dialog"
        width="30%"
        center>
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="3"><span>微信号:</span></el-col>
        <el-col :span="15">
          <el-input
              placeholder="请输入内容"
              v-model="wechat_number"
              clearable>
          </el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="3"><span>图片:</span></el-col>
        <el-col :span="15">
          <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUploads"
              >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="7">
          <el-button >取 消</el-button>
        </el-col>
        <el-col :span="7">
          <el-button type="primary" >确 定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  changePerfect,
  changeUserInfo,getAge, getCar, getDistrict,
  getEdu,
  getGender, getHeight, getHouse,
  getIncome, getIsReal, getMarital, getPerfect, getWeight,
  getZodiac,
  showUserInfo
} from "@/https";

export default {
  name: "UserVerify",
  data() {
    return {
      userInfo: {
        id: "",
        perfect: "",
        pageNum: 1,
        limit: 10
      },
      userInfoModel: {
        userId: "",
        parentName: "",
        parentPhone: "",
        parentImg: "",
        gender: "",
        img: "",
        province: "",
        provinceShow: "",
        city: "",
        cityShow: "",
        age: "",
        height: "",
        weight: "",
        zodiac: "",
        edu: "",
        income: "",
        car: "",
        house: "",
        job: "",
        school: "",
        marital: "",
        detailed: "",
        perfect: "",
        isReal: "",
        reCityList: "",
        reProvinceList: "",
        reEdu: "",
        reHeightMin: "",
        reHeightMax: "",
        reAgeMin: "",
        reAgeMax: "",
        reCar: "",
        reHouse: "",
        reMarital: ""
      },
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
      options6: [],
      options7: [],
      options8: [],
      options9: [],
      options10: [],
      options11: [],
      options12: [],
      options13: [],
      options14: [],
      checkList: [],
      reason: "资料审核通过",
      visible: false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: 0,
      file: '',
      matchmaker_dialog:false,//红娘弹出框
      imageUrl: '',//红娘图片
      wechat_number:'',//红娘账号

    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.userInfo.limit = val
      this.showVerify()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.userInfo.pageNum = val
      this.showVerify()
    },
    handleParentSuccess(res) {
      this.userInfoModel.parentImg = res.key
    },
    handleChildSuccess(res) {
      this.userInfoModel.img = res.key
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
    resetButtonValue() {
      this.userInfo.id = ''
      this.userInfo.perfect = 2
      this.userInfo.pageNum = 1
      this.userInfo.limit = 10
      this.showVerify()
    },
    showVerify() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showUserInfo({
          userId: this.userInfo.userId,
          perfect: this.userInfo.perfect,
          pageNum: this.userInfo.pageNum,
          limit: this.userInfo.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              userId: data.list[i].userId,
              parentName: data.list[i].parentName,
              parentImg: data.list[i].parentImg,
              img: data.list[i].img,
              job: data.list[i].job,
              school: data.list[i].school,
              detailed: data.list[i].detailed,
              perfect: data.list[i].perfectShow,
              isReal: data.list[i].isRealShow
            })
          }
        })
      })
    },
    changeVerify(index, row, verifyStatus, reason) {
      return new Promise(() => {
        changePerfect({
          userId: row.userId,
          reason: reason.toString(),
          perfect: verifyStatus,
        }).then(res => {
          if (res.data.code === 200) {
            this.showVerify()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    handleEdit(index, row) {
      return new Promise(() => {
        showUserInfo({
          userId: row.userId,
        }).then(res => {
          let data = res.data.data
          this.dialogFormVisible = true
          this.userInfoModel.userId = data.list[0].userId
          this.userInfoModel.parentName = data.list[0].parentName
          this.userInfoModel.parentPhone = data.list[0].parentPhone
          this.userInfoModel.parentImg = data.list[0].parentImg
          this.userInfoModel.img = data.list[0].img
          this.userInfoModel.gender = data.list[0].gender
          this.userInfoModel.province = data.list[0].province
          this.userInfoModel.provinceShow = data.list[0].provinceShow
          this.userInfoModel.city = data.list[0].city
          this.userInfoModel.cityShow = data.list[0].cityShow
          this.userInfoModel.age = data.list[0].age
          this.userInfoModel.height = data.list[0].height
          this.userInfoModel.weight = data.list[0].weight
          this.userInfoModel.edu = data.list[0].edu
          this.userInfoModel.income = data.list[0].income
          this.userInfoModel.zodiac = data.list[0].zodiac
          this.userInfoModel.car = data.list[0].car
          this.userInfoModel.house = data.list[0].house
          this.userInfoModel.marital = data.list[0].marital
          this.userInfoModel.job = data.list[0].job
          this.userInfoModel.school = data.list[0].school
          this.userInfoModel.detailed = data.list[0].detailed
          this.userInfoModel.perfect = data.list[0].perfect
          this.userInfoModel.isReal = data.list[0].isReal
          this.getCity2()
        })
      })
    },
    changeUserInfo() {
      return new Promise(() => {
        changeUserInfo({
          userId: this.userInfoModel.userId,
          parentName: this.userInfoModel.parentName,
          parentPhone: this.userInfoModel.parentPhone,
          parentImg: this.userInfoModel.parentImg,
          img: this.userInfoModel.img,
          gender: this.userInfoModel.gender,
          province: this.userInfoModel.province,
          city: this.userInfoModel.city,
          age: this.userInfoModel.age,
          height: this.userInfoModel.height,
          weight: this.userInfoModel.weight,
          edu: this.userInfoModel.edu,
          income: this.userInfoModel.income,
          zodiac: this.userInfoModel.zodiac,
          car: this.userInfoModel.car,
          house: this.userInfoModel.house,
          marital: this.userInfoModel.marital,
          job: this.userInfoModel.job,
          school: this.userInfoModel.school,
          detailed: this.userInfoModel.detailed,
          perfect: this.userInfoModel.perfect,
          isReal: this.userInfoModel.isReal
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showUserInfo()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    getGender() {
      return new Promise(() => {
        getGender({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options1.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getZodiac() {
      return new Promise(() => {
        getZodiac({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options2.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getEdu() {
      return new Promise(() => {
        getEdu({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options3.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getIncome() {
      return new Promise(() => {
        getIncome({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options4.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getCar() {
      return new Promise(() => {
        getCar({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options5.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getHouse() {
      return new Promise(() => {
        getHouse({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options6.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getMarital() {
      return new Promise(() => {
        getMarital({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options7.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getPerfect() {
      return new Promise(() => {
        getPerfect({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options8.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getIsReal() {
      return new Promise(() => {
        getIsReal({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options9.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getProvince() {
      return new Promise(() => {
        getDistrict({
          pid: 1
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options10.push({
              value: data[i].id,
              name: data[i].district,
            })
          }
        })
      })
    },
    getCity1() {
      this.options11 = []
      this.userInfo.city = ''
      return new Promise(() => {
        getDistrict({
          pid: this.userInfo.province
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options11.push({
              value: data[i].id,
              name: data[i].district,
            })
          }
        })
      })
    },
    getCity2() {
      this.options11 = []
      return new Promise(() => {
        getDistrict({
          pid: this.userInfoModel.province
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options11.push({
              value: data[i].id,
              name: data[i].district,
            })
          }
        })
      })
    },
    getAge() {
      return new Promise(() => {
        getAge({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options12.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getHeight() {
      return new Promise(() => {
        getHeight({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options13.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    getWeight() {
      return new Promise(() => {
        getWeight({}).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.length; i++) {
            this.options14.push({
              value: data[i].value,
              name: data[i].name,
            })
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    },
    /**
     *  上传红娘微信
     * */

    handleAvatarSuccess(res, file) {
      console.log(res,file)
      // this.imageUrl = URL.createObjectURL(file.raw);
      // console.log(this.imageUrl)
    },

    beforeAvatarUploads(file) {
      const isJPG = file.type === 'image/jpeg';
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
    }



  },
  mounted: function () {
    this.resetButtonValue();
    this.showVerify();
    this.getGender();
    this.getZodiac();
    this.getEdu();
    this.getCar();
    this.getHouse();
    this.getIncome();
    this.getMarital();
    this.getPerfect();
    this.getIsReal();
    this.getProvince();
    this.getAge();
    this.getHeight();
    this.getWeight();
  }
}
</script>

<style scoped>
.el-upload--picture-card {
  width: 50px;
  height: 50px;
}

.el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.avatar {
  width: 70px;
  height: 70px;

}

.el-dialog {
  transform: translateY(-50%);
  margin-top: 0 !important;
  top: 50%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  margin: 30px 0px;
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>