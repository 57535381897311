<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="vip.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>会员类型：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="vip.type" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="vip.pageNum=1,showUserVip()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-button type="primary" @click="openDialog">增加会员时长</el-button>
      <br><br>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="用户Id" width="100"/>
        <el-table-column prop="type" label="会员类型" width="150"/>
        <el-table-column prop="endAt" label="会员结束时间"/>
        <el-table-column prop="updateAt" label="更新时间"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="vip.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="vip.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="增加会员时长" :visible.sync="dialogFormVisible" center style="width: 1500px" :modal-append-to-body = "false">
      <el-form :model="form" style="margin: 0 30%">
        <el-form-item label="用户Id" :label-width="formLabelWidth">
          <el-input v-model="form.userId" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="会员类型" :label-width="formLabelWidth">
          <el-select v-model="form.type" placeholder="会员类型" style="width: 200px">
            <el-option label="月会员" value="1"></el-option>
            <el-option label="终身会员" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="增加时长(天)" :label-width="formLabelWidth">
          <el-input v-model="form.times" style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUserVipTime">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {formatDateTime} from "@/libs/tools";
import {addUserVipTime, showUserVip} from "@/https";

export default {
  name: "Star.vue",
  data() {
    return {
      vip: {
        userId: "",
        type: "",
        pageNum: 1,
        limit: 10
      },
      form:{
        userId: "",
        type: "",
        times: "",
      },
      options: [{
        value: '0',
        label: '非会员'
      }, {
        value: '1',
        label: '月会员'
      }, {
        value: '2',
        label: '终身会员'
      }],
      dialogFormVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.vip.limit = val
      this.showUserVip()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.vip.pageNum = val
      this.showUserVip()
    },
    resetButtonValue() {
      this.vip.userId = ''
      this.vip.type = ''
      this.vip.pageNum = 1
      this.vip.limit = 10
      this.showUserVip()
    },
    openDialog(){
      this.form.userId = ''
      this.form.type = ''
      this.form.times = ''
      this.dialogFormVisible = true
    },
    showUserVip() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showUserVip({
          userId: this.vip.userId,
          type: this.vip.type,
          pageNum: this.vip.pageNum,
          limit: this.vip.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              userId: data.list[i].userId,
              type: data.list[i].type === 0 ? "非会员" : data.list[i].type === 1 ? "月会员" : "终身会员",
              endAt: formatDateTime(data.list[i].endAt * 1000),
              updateAt: formatDateTime(data.list[i].updateAt * 1000)
            })
          }
        })
      })
    },
    addUserVipTime(){
      return new Promise(() => {
        addUserVipTime({
          userId: this.form.userId,
          type: this.form.type,
          times: this.form.times
        }).then(res => {
          if (res.data.code === 200){
            this.dialogFormVisible = false
            this.showUserVip()
          }else {
            this.open(res.data.msg)
          }
        })
      })
    },
  },
  mounted: function () {
    this.showUserVip();
  }
}
</script>

<style scoped>

</style>