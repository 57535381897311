<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>账号：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="user.userId"></el-input>
        </el-col>
        <el-col :span="1.5" :offset="0.5">
          <div>状态：</div>
        </el-col>
        <el-col :span="3">
          <el-select v-model="user.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="user.pageNum=1,showUser()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="用户Id" width="100"/>
        <el-table-column prop="openId" label="openId" width="300"/>
        <el-table-column prop="createAt" label="创建时间" width="180"/>
        <el-table-column prop="lastLoginAt" label="最近登录时间" width="180"/>
        <el-table-column prop="status" label="状态" width="100"/>
        <el-table-column label="是否禁用" width="80">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                @click="changeEnable(scope.$index, scope.row,1)"
                v-if="scope.row.enable==='正常'"
                v-text="scope.row.enable">
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="changeEnable(scope.$index, scope.row,0)"
                v-if="scope.row.enable==='禁用'"
                v-text="scope.row.enable">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="user.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="user.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  showUser,
  changeEnable
} from "@/https";
import {
  formatDateTime
} from "@/libs/tools";

export default {
  name: "User.vue",
  data() {
    return {
      user: {
        userId: "",
        status: "",
        enable: "",
        pageNum: 1,
        limit: 10
      },
      options: [{
        value: '0',
        label: '正常'
      }, {
        value: '1',
        label: '隐身'
      }, {
        value: '2',
        label: '注销'
      }],
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: 0,
      radio: '正常'
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.user.limit = val
      this.showUser()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.user.pageNum = val
      this.showUser()
    },
    resetButtonValue() {
      this.user.userId = ''
      this.user.status = ''
      this.user.pageNum = 1
      this.user.limit = 10
      this.showUser()
    },
    showUser() {
      this.tableData = []
      return new Promise(() => {
        showUser({
          userId: this.user.userId,
          status: this.user.status,
          pageNum: this.user.pageNum,
          limit: this.user.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              userId: data.list[i].userId,
              openId: data.list[i].openId,
              status: data.list[i].status === 0 ? '正常' : data.list[i].status === 1 ? '隐身' : '注销',
              enable: data.list[i].enable === 0 ? '正常' : '禁用',
              createAt: formatDateTime(data.list[i].createAt * 1000),
              lastLoginAt: data.list[i].lastLoginAt === data.list[i].createAt ? '新注册' : formatDateTime(data.list[i].lastLoginAt * 1000)
            })
          }
        })
      })
    },
    changeEnable(index,row,enable) {
      return new Promise(() => {
        changeEnable({
          userId: row.userId,
          enable: enable
        }).then(res => {
          if (res.data.code === 200) {
            this.dialogFormVisible = false
            this.showUser()
          } else {
            this.open(res.data.msg)
          }
        })
      })
    },
    open(msg) {
      this.$message.error(msg)
    }
  },
  mounted: function () {
    this.showUser();
  }
}
</script>

<style scoped>

</style>