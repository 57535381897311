import Vue from "vue";
import VueRouter from "vue-router";
import {getToken} from "@/https";
import Login from "@/pages/Login";
import Main from "@/components/Main";
import UserRegister from "@/pages/UserRegister";
import UserAccount from "@/pages/UserAccount";
import UserInfo from "@/pages/UserInfo";
import UserVerify from "@/pages/UserVerify";
import Order from "@/pages/Order";
import OrderIncome from "@/pages/OrderIncome";
import Star from "@/pages/Star";
import Phone from "@/pages/Phone";
import Share from "@/pages/Share";
import Logoff from "@/pages/Logoff";
import UserVip from "@/pages/UserVip";

Vue.use(VueRouter);

const LOGIN_PAGE_NAME = 'login'
const routes = [
    {
        path: '/',
        redirect: {
            name: 'login'
        }
    },
    {
        path: '/login',
        name: 'login',//路由名称
        component: Login//组件对象
    },
    {
        path: '/user',
        meta: {
            access: [],
            title: "用户账号"
        },
        component: Main,
        children: [
            {
                path: "/",
                name: 'user',
                component: UserRegister
            },
            {
                path: '/userRegister',
                name: 'userRegister',
                component: UserRegister
            },
            {
                path: '/userAccount',
                name: 'userAccount',
                component: UserAccount
            },
            {
                path: '/userInfo',
                name: 'userInfo',
                component: UserInfo
            },
            {
                path: '/userVip',
                name: 'userVip',
                component: UserVip
            },
            {
                path: '/userVerify',
                name: 'userVerify',
                component: UserVerify
            }
        ]
    },
    {
        path: '/order',
        component: Main,
        children: [
            {
                path: '/order',
                name: 'order',
                component: Order
            },
            {
                path: '/orderIncome',
                name: 'orderIncome',
                component: OrderIncome
            }
        ]
    },
    {
        path: '/record',
        component: Main,
        children: [
            {
                path: '/star',
                name: 'star',
                component: Star
            },
            {
                path: '/phone',
                name: 'phone',
                component: Phone
            },
            {
                path: '/share',
                name: 'share',
                component: Share
            },
            {
                path: '/logoff',
                name: 'logoff',
                component: Logoff
            }
        ]
    }
];

const router = new VueRouter({
    routes,
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    const token = getToken()
    if (!token && to.name !== LOGIN_PAGE_NAME) {
        // 未登录且要跳转的页面不是登录页
        next({
            name: LOGIN_PAGE_NAME // 跳转到登录页
        })
    } else if (!token && to.name === LOGIN_PAGE_NAME) {
        // 未登陆且要跳转的页面是登录页
        next() // 跳转
    } else {
        if (token === null || token === '') {
            next('login');
        } else {
            next();
        }
    }
});


export default router;
