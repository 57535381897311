<template>
  <div>
    <el-card>
      <el-row :gutter="24">
        <el-col :span="1.5">
          <div>用户Id：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="logoff.userId"></el-input>
        </el-col>
        <el-col :span="1.5">
          <div>openId：</div>
        </el-col>
        <el-col :span="2">
          <el-input v-model="logoff.openId"></el-input>
        </el-col>
        <el-button style="margin-left: 20px" type="primary" @click="logoff.pageNum=1,showLogoff()">查询</el-button>
        <el-button style="margin-left: 20px" @click="resetButtonValue">重置</el-button>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="注销编号" width="100"/>
        <el-table-column prop="userId" label="用户Id" width="150"/>
        <el-table-column prop="openId" label="openId" width="350"/>
        <el-table-column prop="status" label="注销状态" width="150"/>
        <el-table-column prop="createAt" label="注销时间"/>
      </el-table>
      <br>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="logoff.pageNum"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="logoff.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {formatDateTime} from "@/libs/tools";
import {showLogoff} from "@/https";

export default {
  name: "Share.vue",
  data() {
    return {
      logoff: {
        userId: "",
        openId: "",
        pageNum: 1,
        limit: 10
      },
      tableData: [],
      total: 0
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.logoff.limit = val
      this.showLogoff()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.logoff.pageNum = val
      this.showLogoff()
    },
    resetButtonValue() {
      this.logoff.userId = ''
      this.logoff.openId = ''
      this.logoff.pageNum = 1
      this.logoff.limit = 10
      this.showLogoff()
    },
    showLogoff() {
      this.tableData = []
      this.spinShow = true
      return new Promise(() => {
        showLogoff({
          userId: this.logoff.userId,
          openId: this.logoff.openId,
          pageNum: this.logoff.pageNum,
          limit: this.logoff.limit
        }).then(res => {
          this.spinShow = false
          let data = res.data.data
          this.total = data.totalCount
          for (let i = 0; i < data.list.length; i++) {
            this.tableData.push({
              id: data.list[i].id,
              userId: data.list[i].userId,
              openId: data.list[i].openId,
              status: data.list[i].status === 0 ? "提交中" : data.list[i].status === 1 ? "已注销" : "取消注销",
              createAt: data.list[i].createAt === null ? "" : formatDateTime(data.list[i].createAt * 1000)
            })
          }
        })
      })
    }
  },
  mounted: function () {
    this.showLogoff();
  }
}
</script>

<style scoped>

</style>