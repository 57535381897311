<template>
  <div style="overflow: auto">
    <div style="width: 150px;float: left;position: absolute">
      <el-menu
          style="height:100vh;"
          default-active="/userRegister"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router>
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>用户信息</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/userRegister" style="min-width: 0">用户注册量</el-menu-item>
            <el-menu-item index="/userAccount" style="min-width: 0">用户账户</el-menu-item>
            <el-menu-item index="/userInfo" style="min-width: 0">用户信息</el-menu-item>
            <el-menu-item index="/userVip" style="min-width: 0">用户会员</el-menu-item>
            <el-menu-item index="/userVerify" style="min-width: 0">资料审核</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-shopping-cart-full"></i>
            <span>订单</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/order" style="min-width: 0">订单查询</el-menu-item>
            <el-menu-item index="/orderIncome" style="min-width: 0">订单收益</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-star-on"></i>
            <span>操作记录</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/star" style="min-width: 0">收藏</el-menu-item>
            <el-menu-item index="/phone" style="min-width: 0">查看手机号</el-menu-item>
            <el-menu-item index="/share" style="min-width: 0">分享</el-menu-item>
            <el-menu-item index="/logoff" style="min-width: 0">注销</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
    <div style="width: 1700px;float: left;position: absolute;margin-left: 150px">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {useIndex} from "@/libs/utilsDram.js"

export default {
  name: "Main.vue",
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  mounted() {
    const {calcRate, windowDraw} = useIndex(this.$refs.appRef)
    calcRate()
    windowDraw()
  }
}
</script>

<style scoped>
.main {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
}

.layout-container {
  width: 100%;
  height: 100%;
}

</style>